import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */
import './src/scss/app.scss';
import './src/js/app';
import './styles/app.css';
import 'select2/src/scss/core.scss'
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css'

import '@fortawesome/fontawesome-free/js/all.js';

import $ from 'jquery';
global.$ = global.jQuery = $;

import 'bootstrap-icons/font/bootstrap-icons.css';

// Puoi anche inizializzare eventuali script che dipendono da jQuery qui
$(document).ready(function () {
    // console.log('jQuery è pronto!');
});

// console.log('This log comes from assets/app.js - welcome to AssetMapper! 🎉');

// import "https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback";
// import 'admin-lte/plugins/fontawesome-free/css/all.css';
// import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.css';
// import 'admin-lte/build/js/AdminLTE';
// import 'admin-lte/dist/css/adminlte.css';
