import "../scss/app.scss";

// AdminKit (required)
import "./modules/bootstrap";
import "./modules/sidebar";
import "./modules/theme";
import "./modules/feather";

// Charts
import "./modules/chartjs";

// Forms
import "./modules/flatpickr";

// Maps
import "./modules/vector-maps";

import {$, jQuery} from 'jquery';

window.$ = $;
window.jQuery = jQuery;

// import validate from 'jquery-validation';

// $.validator.setDefaults({
//     // lang: 'it',
//     highlight: function (element) {
//         if ($(element).hasClass('select2-hidden-accessible')) {
//             $(element).next("span.select2").children('span.selection').children('span.select2-selection').addClass('is-invalid').removeClass('is-valid');
//         } else {
//             $(element).addClass("is-invalid").removeClass("is-valid");
//         }
//     }, unhighlight: function (element) {
//         var elm = $(element);
//         if (elm.hasClass('select2-hidden-accessible')) {
//             elm.next("span.select2").children('span.selection').children('span.select2-selection').addClass('is-valid').removeClass('is-invalid');
//         } else if (elm.prop("type") === "radio") {
//             $('input[name="' + elm.attr('name') + '"]').each(function (i, e) {
//                 $(e).removeClass("is-invalid");
//             });
//         } else {
//             elm.addClass("is-valid").removeClass("is-invalid");
//         }
//     }, errorElement: 'em', // errorClass: 'help-block',
//     errorPlacement: function (error, element) {
//         error.addClass("invalid-feedback");
//         if (element.prop("type") === "checkbox") {
//             error.insertAfter(element.next("label"));
//         } else if (element.hasClass('select2-hidden-accessible')) {
//             error.insertAfter(element.next("span.select2"));
//         } else if (element.prop("type") === "radio") {
//             $('input[name="' + element.attr('name') + '"]').each(function (i, e) {
//                 $(e).addClass("invalid-feedback is-invalid");
//             });
//             error.appendTo(element.parents(".radio-group"));
//         } else {
//             error.insertAfter(element);
//         }
//     }, ignore: ".summernote"
// });

// global.init = function () {
//     if ($().validate != undefined) {
//         $('.form.default-validate').each(function () {
//             $(this).validate(validation_options);
//         });
//
//         $(".select2").on("change", function (e) {
//             try {
//                 if ($(this).rules() != undefined) {
//                     $(this).valid();
//                 }
//             } catch (e) {
//                 return;
//             }
//         });
//     }
// }
//
// jQuery(document).ready(function ($) {
//     init();
// });